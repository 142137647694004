import React, {useState, useEffect} from 'react';
import {getHistoryRequest} from '../../../../requests';
import { Select, Tag, Typography, Space, List, Card, Button } from 'antd';
import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { ArrowIcon, CopyIcon } from '../../../../elements/icons';
import './styles.css';

const useGetHistory = () => {
  const [history, setHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const getHistory = async () => {
    setLoading(true);
    const data = await getHistoryRequest(page);
    setHistory(data.results);
    setTotalPages(data.count);
    setLoading(false);
  }

  useEffect(() => {
    getHistory();
  }, [page]);

  return {history, loading, error, page, setPage, totalPages};
}


export default function HistoryPage() {
  const {history, loading, error, page, setPage, totalPages} = useGetHistory();
  return <div>
    {
      loading || !history ? <div>Loading...</div> :
      error ? <div>Error</div> :
      <History items={history} page={page} setPage={setPage} totalPages={totalPages} />
    }
  </div>;
}

function History({items, page, setPage, totalPages}: {items: any[], page: any, setPage: any, totalPages: any}) {

  return <div className="centered">
      <div className="history-content">
        <div className="history-header">History</div>
          <div className='history-page'>
            {items.map((item) => (
              <HistoryItem item={item} />
            ))}
        </div>
        <Pagination total={totalPages} pageSize={10} current={page} setCurrentPage={setPage}/>
      </div>
  </div> 
}


const HistoryItem = ({item}: {item: any}) => {
  const [expanded, setExpanded] = useState(false);

  const text = expanded ? item.text : item.text.slice(0, 50);

  return (
    <div className='history-item' style={{display: 'flex'}}>
      <div className="history-content-date" onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}}>
        <div style={{transform: expanded ? 'rotate(180deg)' : '', display: 'inline-block'}}>
          <ArrowIcon />
        </div>
        <span>Today 16:44</span>
      </div>
      <div className="history-content-text">
        {text}
      </div>
      <div className="history-item-tools">
        <div onClick={() => {
          navigator.clipboard.writeText(item.text);
        }}><CopyIcon /></div>
        <button className="secondary history-btn" onClick={() => {
          window.location.href = `/main/scan?text=${encodeURIComponent(item.text)}`;
        }}>scan again</button>
        <AIWidget score={item.answer * 100}/>
      </div>
    </div>
  );
}

const Pagination = (props: {total: number, pageSize: number, current: number, setCurrentPage: any}) => {
  const totalPages = Math.ceil(props.total / props.pageSize);
  const [pages, setPages] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const newPages = [];
    
    // First page
    newPages.push(
      <button 
        key="first"
        className="secondary small" 
        onClick={() => props.setCurrentPage(1)}
        disabled={props.current === 1}
      >
        «
      </button>
    );
  
    // Previous page
    newPages.push(
      <button
        key="prev"
        className="secondary small"
        onClick={() => props.setCurrentPage(props.current - 1)}
        disabled={props.current === 1}
      >
        ‹
      </button>
    );

    // Calculate range of pages to show
    let startPage = Math.max(1, props.current - 2);
    let endPage = Math.min(totalPages, startPage + 4);
    
    // Adjust start if we're near the end
    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }

    // Add page numbers
    for (let i = startPage; i <= endPage; i++) {
      newPages.push(
        <button
          key={i}
          className={`secondary small ${props.current === i ? 'primary' : ''}`}
          onClick={() => props.setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    // Add ellipsis and last page if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        newPages.push(
          <span key="ellipsis" style={{padding: '0 4px'}}>...</span>
        );
      }
      newPages.push(
        <button
          key={totalPages}
          className={`secondary small ${props.current === totalPages ? 'primary' : ''}`}
          onClick={() => props.setCurrentPage(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    // Next page
    newPages.push(
      <button
        key="next"
        className="secondary small"
        onClick={() => props.setCurrentPage(props.current + 1)}
        disabled={props.current === totalPages}
      >
        ›
      </button>
    );
  
    // Last page
    newPages.push(
      <button
        key="last"
        className="secondary small"
        onClick={() => props.setCurrentPage(totalPages)}
        disabled={props.current === totalPages}
      >
        »
      </button>
    );

    setPages(newPages);
  }, [props.current, totalPages, props.setCurrentPage]);
  
  return (
    <div style={{display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center', margin: '20px 0'}}>
      {pages}
    </div>
  );
}

interface IAIWidget {
  score: number;
}
const AIWidget = (props: IAIWidget) => {
  const {score} = props;

  return <div className={'score ' + (score <= 40 ? 'success-score' : score <= 60 ? 'warning-score' : 'danger-score')}>{score.toFixed(0)}% AI</div>
}